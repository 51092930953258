/* Общие стили */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Header */
.header {
  background-color: #2563eb;
  color: white;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.phone-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Product List */
.product-list {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.product-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.product-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.product-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.product-company {
  font-size: 0.875rem;
  color: #666;
}

.product-details {
  padding: 1rem;
  background-color: #f9fafb;
  border-top: 1px solid #e5e7eb;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.label {
  font-weight: 500;
}

.total-price {
  grid-column: span 2;
  margin-top: 0.5rem;
}

/* Адаптивность */
@media (max-width: 640px) {
  .product-grid {
    grid-template-columns: 1fr;
  }
  
  .total-price {
    grid-column: 1;
  }
  
  .header {
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
  }
}

.install-prompt {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  width: 90%;
  max-width: 400px;
}

.prompt-content {
  text-align: center;
}

.prompt-content p {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
}

.prompt-content ol {
  text-align: left;
  margin: 16px 0;
  padding-left: 24px;
}

.prompt-content li {
  margin: 8px 0;
}

.install-button {
  background: #2563eb;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  margin: 8px;
  cursor: pointer;
}

.close-button {
  background: #e5e7eb;
  color: #374151;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  margin: 8px;
  cursor: pointer;
}
